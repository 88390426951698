import table from '../../assets/table.png'
import styles from './Table.module.scss'

export const Table = () => {

    return (
        <>
          <img src={table} className={styles.img} />
        </>
    )
};
